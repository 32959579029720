<div class="comment-container">
  <textarea *ngIf="isNonUser"
      rows="1"
      placeholder="{{'MEMOS.COMMENT-HERE'|translate}}"
      autosize
      [(ngModel)]="newComment"
      [minRows]="1"
      [maxRows]="6"
      (keydown.enter)="handleEnterKey($event)">
  </textarea>

  <div #messageInput
      id="messageInput"
      *ngIf="!isNonUser"
      class="textarea-post post-width custom-box"
      [attr.placeholder]="'Aa'"
      [(ngModel)]="newComment"
      [minRows]="1"
      [maxRows]="6"
      [mention]="profileList"
      [mentionConfig]="mentionConfig"
      (searchTerm)="delaySearch($event)"
      (opened)="mentionOpened()"
      (closed)="mentionClosed()"
      (keydown.enter)="handleEnterKey($event)"
      [mentionListTemplate]="mentionListTemplate"
      [preventEnter]="true"
      autosize
      appContentEditable
      appMentionHighlight>
  </div>
  <svg class="pointer comment-icon icon-attachment"
      (click)="image.click()"
      stroke="currentColor"
      height="20"
      width="20">
    <use href="assets/images/icons/objects/paperclip.svg#paperclip"
        height="20"
        width="20"
        (click)="addComment()">
    </use>
  </svg>
  <button class="border-icon-send"
      (click)="addComment()"
      [disabled]="isLoadingComment || !newComment">
    <svg class="pointer comment-icon"
        stroke="currentColor"
        height="20"
        width="20">
      <use href="assets/images/icons/communications/paper-plane.svg#paper-plane"
          height="20"
          width="20">
      </use>
    </svg>
  </button>
  <input type="file"
      [accept]="acceptFileType"
      class="form-control m-t-10"
      [(ngModel)]="fileUpload"
      (change)="uploadFile($event)"
      [multiple]="true"
    #image
      hidden>
</div>

<ng-template #mentionListTemplate
  let-item="item">
  <div class="custom-mention-item">
    <div class="avatar">
      <app-profile-display class="w-100"
          [size]="32"
          [person]="$any(item.context)">
      </app-profile-display>
    </div>
    <div class="d-flex flex-column">
      <div class="text-black-pantone fs-base-l2 item-name">
        {{ item.context.full_name }} ({{ item.context.nickname || '-' }})
      </div>
      <div class="text-gray-neutrals fs-base-l7">{{ item.context.email }}</div>
    </div>
  </div>
</ng-template>