import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const memoPasswordRouteGuard: CanActivateFn = (
  route,
  _state,
) => {
  const router = inject(Router);
  const payload = JSON.parse(atob(route.params['token']));
  if (!('email_auth_key' in payload) || !('memo_id' in payload)) {
    return router.createUrlTree(['/']);
  }
  return router.navigate(
    ['/memos/preview', payload.memo_id.toString()],
    {
      state: { email_auth_key: payload.email_auth_key },
      replaceUrl: true,
    },
  );
};
