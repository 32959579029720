<div *ngIf="style === 'text'"
    class="lang">
  <div class="pointer"
      [ngClass]="{'select-lang': currentLang === 'th'}"
      (click)="changeLanguage('th')">TH
  </div>
  <div class="mx-1">/</div>
  <div class="pointer"
      [ngClass]="{'select-lang': currentLang === 'en'}"
      (click)="changeLanguage('en')">EN
  </div>
</div>

<div *ngIf="style === 'dropdown'"
    #dd="ngbDropdown"
    ngbDropdown
    [autoClose]="'outside'"
    placement="bottom-end">
  <button class="btn btn btn-translate d-flex justify-content-center mx-2"
      ngbDropdownToggle
      [ngClass]="{'hover-effect': enableHover}"
      id="dropdownLang"
      type="button">
    <svg-icon [src]="'assets/svg/icon-menu/' + (currentLang === 'th' ? 'translate-thai' : 'translate-eng') + '.svg'">
    </svg-icon>
    <span class="px-base-l8 fs-base-u0 fw-semibold">{{ currentLang === 'th' ? 'TH' : 'EN' }}</span>
    <i class="fal fa-angle-down fs-base-u4"></i>
  </button>
  <div ngbDropdownMenu
      aria-labelledby="dropdownLang"
      class="lang-menu text-center"
      [ngClass]="{'mx-2': enableHover}">
    <button ngbDropdownItem
        class="text-start select-item-dropdown"
        (click)="changeLanguage('en'); dd.close()"
        [class.bg-selected]="currentLang === 'en' ? 'bg-selected' : null">
      EN
    </button>
    <button ngbDropdownItem
        class="text-start select-item-dropdown"
        (click)="changeLanguage('th'); dd.close()"
        [class.bg-selected]="currentLang === 'th' ? 'bg-selected' : null">
      TH
    </button>
  </div>
</div>