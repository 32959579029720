<ng-template #deleteConfirmationModal
    let-modal>
  <app-modal-confirmation-std4-skin [dismissText]="'COMMON.Cancel' | translate"
      [header]="'MEMOS.Delete Confirmation' | translate"
      [submitText]="'COMMON.Confirm' | translate"
      [title]="title || ('MEMOS.Are you sure to delete memo draft?' | translate)"
      [subtitle]="'MEMOS.This item will be permanent deleted.' | translate"
      (dismiss)="modal.dismiss()"
      (submitted)="submit()">
    <ng-template>
      <svg height="56"
          width="56"
          fill="#1958d3">
        <use height="56"
            width="56"
            href="assets/images/icons/marks/question-fill.svg#question-fill"></use>
      </svg>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</ng-template>

<i *ngIf="type==='icon' && !useNewDesign"
    class="fas fa-trash icon-btn red p-0"
    (click)="$event.stopPropagation(); open(deleteConfirmationModal)">
  <i class="fas fa-trash"
      *ngIf="!useNewDesign"></i>
</i>

<div *ngIf="type==='icon' && useNewDesign"
    (click)="open(deleteConfirmationModal)">
  <svg class="pointer icon-btn-svg"
      stroke="currentColor">
    <use href="assets/images/icons/editing/trash.svg#trash"
        height="20"
        width="20">
    </use>
  </svg>
  <span *ngIf="showDeleteMessage"
      class="ms-2 pointer">
    {{ deleteMessage|translate }}
  </span>
</div>

<button *ngIf="type === 'text'"
    class="btn btn-danger p-btn"
    (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-w-100'"
    class="btn btn-danger w-100"
    (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-memo'"
    class="btn btn-danger del-btn w-auto"
    (click)="open(deleteConfirmationModal)">
  {{ "MEMOS.DEL-MEMO" | translate }}
</button>

<div *ngIf="type === 'x-icon'"
    (click)="open(deleteConfirmationModal)">
  <i class="fas fa-times pointer"></i>
</div>

<button *ngIf="type === 'tag'"
    class="tag red pointer"
    (click)="open(deleteConfirmationModal)">
  {{ "CREATE-USER.DELETE"|translate }}
</button>