import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  NgSelectComponent,
  NgSelectModule,
} from '@ng-select/ng-select';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import * as _ from 'lodash';
import { ProfileDisplayComponent } from '../profile-display/profile-display.component';

@Component({
  selector: 'app-bubble-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ProfileDisplayComponent,
    TranslateModule,
  ],
  templateUrl: './bubble-dropdown.component.html',
  styleUrls: ['./bubble-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BubbleDropdownComponent,
      multi: true,
    },
  ],
})
export class BubbleDropdownComponent
  implements AfterViewInit, ControlValueAccessor
{
  @Input() allSelectionText = 'COMMON.All';
  @Input() appendTo = '';
  @Input() bindLabel: string;
  @Input() bindImage = 'photo';
  @Input() bindValue: string;
  @Input() clearable = true;
  @Input() defaultImage = 'assets/images/no-image-with-slash.jpg';
  @Input() disabled: boolean;
  @Input() items: any[] = [];
  @Input() titleAllChoice: string;
  @Input() loading = false;
  @Input() notFoundText: string;
  @Input() showAllSelection = false;
  @Input() showCheckbox = true;
  @Input() showImage = false;
  @Input() showSearchInDropdown = false;
  @Input() invalid = false;
  @Output() add = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @ContentChild('selectedChoiceImgTemp') selectedChoiceImgTemp?:
    | TemplateRef<any>
    | undefined;
  @ContentChild('choiceImageTemp') choiceImageTemp?:
    | TemplateRef<any>
    | undefined;
  @ContentChild('choiceLabelTemp') choiceLabelTemp?:
    | TemplateRef<any>
    | undefined;
  @ContentChild('labelTemp') labelTemp?: TemplateRef<any> | undefined;
  @ViewChild(NgSelectComponent, { read: ElementRef })
  ngSelect?: ElementRef<HTMLElement>;

  inDropdownSearch?: string | null;
  isOpened = false;
  searchInputContainer?: HTMLElement | null;
  selected?: any;

  private searchInputElement?: HTMLInputElement;
  private translate = inject(TranslateService);
  private _onTouch: any;
  private _onChange: (val: any) => void = () => {};

  ngAfterViewInit(): void {
    this.searchInputElement =
      this.ngSelect?.nativeElement.querySelector('.ng-input')
        ?.children[0] as HTMLInputElement | undefined;
    if (this.searchInputElement) {
      this.searchInputElement.placeholder =
        this.translate.instant('COMMON.Search') + '...';
    }
  }

  getImage(item: any): string | undefined {
    return _.get(item, this.bindImage);
  }

  getLabel(item: any): string | undefined {
    return _.get(item, this.bindLabel);
  }

  onInDropdownSearch(val: string): void {
    if (this.searchInputElement) {
      this.searchInputElement.value = val;
      this.searchInputElement.dispatchEvent(new Event('input'));
    }
  }

  onValueChange(val: any) {
    this._onChange(val);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  toggleAllSelection(checked: boolean) {
    this.selected = checked
      ? this.items.map((item) =>
          this.bindValue ? item[this.bindValue] : item,
        )
      : [];
    this.onValueChange(this.selected);
  }

  writeValue(obj: any): void {
    this.selected = obj;
  }

  get isAllChecked() {
    return (
      this.selected &&
      this.selected.length > 0 &&
      this.selected.length === this.items.length
    );
  }
}
