<ng-template #nextApprovalPopup>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <svg-icon src="assets/images/vector.svg"
            [svgStyle]="{ 'width.px': 90, 'height.px': 90 }"></svg-icon>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-5 font">
        {{textMessage}}
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="col-auto text-center">
      <button (click)="previousPage()"
          type="button"
          class="btn p-btn fontbtn"
          style="padding: 10px 15px;">
        {{ 'APPROVAL.CONTINUE-APPROVE' | translate }}
      </button>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="col-auto text-center mb-5">
      <div class="pointer"
          (click)="goToMemoDetail()"
          style="font-size: 13px; text-decoration: underline">
        {{ 'APPROVAL.GO-TO-CHECK-DOCUMENT' | translate }}
      </div>
    </div>
  </div>
</ng-template>
