import { LoaLevel } from './memo.model';

export class SaveMemo {
  static readonly type = '[Memo] SaveMemo';
  constructor(public payload: any) {}
}
export class GetMemoData {
  static readonly type = '[Memo] GetMemoData';
}

export class SaveItemMemo {
  static readonly type = '[Memo] SaveItemMemo';
  constructor(public value: any, public name: string) {}
}
export class CreateMemo {
  static readonly type = '[Memo] CreateMemo';
  constructor(public payload: any) {}
}
export class UpdateMemo {
  static readonly type = '[Memo] UpdateMemo';
  constructor(public id: number, public payload: any) {}
}
export class AddAttachFile {
  static readonly type = '[Memo] AddAttachFile';
  constructor(public file: File) {}
}

export class AddLoaLevel {
  static readonly type = '[Memo Customizer] AddLoaLevel';
  constructor(public name: string) {}
}

export class RemoveLoaLevel {
  static readonly type = '[Memo Customizer] RemoveLoaLevel';
  constructor(public index?: number) {}
}

export class UpdateLoaLevel {
  static readonly type = '[Memo Customizer] UpdateLoaLevel';
  constructor(
    public index: number,
    public updatedLoaLevel: LoaLevel,
  ) {}
}

export class ResetLoa {
  static readonly type = '[Memo Customizer] ResetLOA';
  constructor() {}
}

export class ResetState {
  static readonly type = '[Memo] ResetState';
  constructor() {}
}
export class UploadFile {
  static readonly type = '[Memo] UploadFileMemo';
  constructor(public id: number) {}
}
export class UploadPDFMemo {
  static readonly type = '[Memo] UploadPDFMemo';
  constructor(public id: number) {}
}
export class ErrorNotification {
  static readonly type = '[Memo] SetError Notification';
  constructor(public error: any) {}
}
export class PreviewMemo {
  static readonly type = '[Memo] Preview Memo';
  constructor(public payload: any) {}
}

export class ShowMemoPasswordPopup {
  static readonly type = '[Memo] ShowMemoPasswordPopup';
  constructor(
    public isCreator: boolean,
    public memoId: number | null,
  ) {}
}

export class CloseMemoPasswordPopup {
  static readonly type = '[Memo] CloseMemoPasswordPopup';
  constructor(public password: string | null) {}
}

export class ClearMemoPasswordPopup {
  static readonly type = '[Memo] ClearMemoPasswordPopup';
  constructor() {}
}
