import { Injectable } from '@angular/core';
import { ApiService } from '@core/http/api.service';
import { asapScheduler, Observable } from 'rxjs';
import {
  MutedNotification,
  NotificationChannel,
  NotificationList,
} from '@shared/service/notification.service';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';
import {
  MyCertificate,
  PersonalCertificateStatus,
} from '../../certificate/shared/certificate.model';
import { Person } from '@shared/models/user.model';
import { PasswordPolicy } from '../../setting/component/theme/component/password-policy/password-policy.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  certificateStatusCache: any = {};
  profileDetail: Person;

  constructor(private http: ApiService, private store: Store) {
    this.profileDetail = this.store.selectSnapshot(
      (state) => state.auth.user_info,
    );
  }

  abbreviateFullName(name: string, splitter = ' '): string {
    if (name == null || name === '') {
      return name;
    }
    name = name.trim();
    const apartNames = name.split(splitter);
    const abbvNames = apartNames.map((apartName) => apartName[0]);
    return abbvNames.join('.') + '.';
  }

  setPassword(data: any) {
    return this.http.put(`/api/change-password/`, data);
  }

  // Delegate
  getDelegate() {
    return this.http.get(`/api/user-delegate/`);
  }

  createDelegate(data: any) {
    return this.http.post(`/api/user-delegate/`, data);
  }

  updateDelegate(id: any, data: any) {
    return this.http.patch(`/api/user-delegate/${id}/`, data);
  }

  deleteDelegate(id: any) {
    return this.http.delete(`/api/user-delegate/${id}/`);
  }

  loadCertificateStatus(
    id: any = null,
    canUseCache: any = false,
    params?: { [k: string]: string },
  ): Observable<PersonalCertificateStatus> {
    let cacheGroupKey: string | any;
    if (id == null)
      id = this.store.selectSnapshot(
        (state) => state.auth.user_info.id,
      );

    /** In case, the params is valid. To prepare cacheGroupKey for accession  */
    if (params) {
      cacheGroupKey = Object.keys(params)
        .map((key) => `${key}_${params[key]}`)
        .join('__');
    }
    const cacheValue: any = params
      ? this.certificateStatusCache[cacheGroupKey]?.[id]
      : this.certificateStatusCache[id];
    // return data from cache to prevent calling API too much.
    if (canUseCache && cacheValue) {
      return new Observable((subscriber) => {
        asapScheduler.schedule(() => {
          subscriber.next(cacheValue);
        });
      }).pipe(take<PersonalCertificateStatus | any>(1));
    }

    return this.http
      .get<PersonalCertificateStatus>(
        `/api/people/${id}/certificate-status/`,
        params,
      )
      .pipe(
        map((data) => {
          // store certificate status into cache.
          const cache = this.certificateStatusCache;
          if (params) {
            if (
              cache[cacheGroupKey] === null ||
              typeof cache[cacheGroupKey] !== 'object'
            ) {
              cache[cacheGroupKey] = {};
            }
            cache[cacheGroupKey][id] = data;
          } else {
            cache[id] = data;
          }
          return data;
        }),
      );
  }

  getWarningEnableOTP() {
    return this.http.get(`/api/people/warning-enable-otp/`);
  }

  updateSelf(id: any, data: any): any {
    return this.http.patch(`/api/people/${id}/update-self/`, data);
  }

  removeBackground(data: FormData): Observable<Blob> {
    return this.http.postResponseBlob<Blob>(
      `/api/remove-img-bg/`,
      data,
    );
  }

  // OTP
  OpenOtp(data: any) {
    return this.http.post(`/api/open-otp/`, data);
  }

  CloseOtp(data: any): any {
    return this.http.post(`/api/close-otp/`, data);
  }

  verifyOtp(data: any): any {
    return this.http.post(`/api/verify-otp/`, data);
  }

  // Setting notifications
  getLinkLineCODIUM() {
    return this.http.get(`/api/line/codium/`);
  }

  getLinkLineUser() {
    return this.http.get(`/api/line/user/`);
  }

  settingEmail(id: any, data: any) {
    return this.http.patch(`/api/mail-notifications/${id}/`, data);
  }

  updatePreferredLanguage(id: any, language: any): any {
    return this.http.patch(
      `/api/people/${id}/update-preferred-language/`,
      { preferred_language: language },
    );
  }

  updateMutedNotification(id: number, data: any) {
    return this.http.patch(`/api/muted-notifications/${id}/`, data);
  }

  getNotificationAction(
    params?: any,
  ): Observable<NotificationList[]> {
    return this.http.get(`/api/notification-actions/`, params);
  }

  getNotificationChannel(
    params?: any,
  ): Observable<NotificationChannel[]> {
    return this.http.get(`/api/notification-channels/`, params);
  }

  getMutedNotification(
    params?: any,
  ): Observable<MutedNotification[]> {
    return this.http.get(`/api/muted-notifications/`, params);
  }

  profile() {
    this.profileDetail = this.store.selectSnapshot(
      (state) => state.auth.user_info,
    );
  }

  getPasswordPolicy() {
    return this.http.get<PasswordPolicy>(`/api/pw-policy/`);
  }

  getCertificateStatus() {
    return this.http.get<MyCertificate>(`/api/my-certificate/`);
  }

  register(fd: FormData) {
    return this.http.post(`/api/register/`, fd);
  }

  registerFormValidate(fd: FormData) {
    return this.http.post(`/api/register/validate/`, fd);
  }
}
