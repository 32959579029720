import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Person, User } from '@shared/models/user.model';
import { ApiService } from '../http/api.service';

import { API_URL } from 'src/app/shared/service/api.constant';
import { Router } from '@angular/router';
import { AuthResponse } from '../../store/auth/auth.model';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '../../store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  larkSDKLoaded = false;

  constructor(
    private http: ApiService,
    private router: Router,
    private zone: NgZone,
    private store: Store,
  ) {}

  public get currentUserValue(): string {
    return (
      this.store.selectSnapshot<string>(
        AuthSelectors.getSlices.access,
      ) ||
      localStorage.getItem('currentUser') ||
      ''
    );
  }

  public get isRegister(): boolean {
    const _isRegister = this.store.selectSnapshot<boolean>(
      AuthSelectors.getSlices.is_register,
    );
    return _isRegister !== null
      ? _isRegister
      : localStorage.getItem('is_register') === 'true';
  }

  public get isPasswordValid(): boolean {
    const _isPasswordValid = this.store.selectSnapshot<boolean>(
      AuthSelectors.getSlices.is_password_valid,
    );
    return _isPasswordValid !== null
      ? _isPasswordValid
      : localStorage.getItem('is_password_valid') === 'true';
  }

  public get role(): string {
    return this.store.selectSnapshot<string>(
      AuthSelectors.getSlices.role,
    );
  }

  public get isResettingPassword(): boolean {
    return this.store.selectSnapshot<boolean>(
      AuthSelectors.getSlices.is_reset_password,
    );
  }

  public get httpHeader(): any {
    if (
      localStorage.getItem('JWTKey') &&
      this.router.url.includes('/approval/')
    ) {
      return { Authorization: localStorage.getItem('JWTKey') };
    }
    return {
      Authorization: `Bearer ${this.currentUserValue}`,
    };
  }

  login(
    username: string,
    password: string,
  ): Observable<AuthResponse> {
    return this.http
      .post<User>('/api/token-auth/', {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(
            'currentUser',
            JSON.stringify(user.access),
          );
          if (user.session_name) {
            sessionStorage.setItem('session_name', user.session_name);
          }
          return user;
        }),
      );
  }

  logout() {
    return this.http.post('/api/logout/', {
      refresh: localStorage.getItem('refresh'),
      session_name: sessionStorage.getItem('session_name'),
    });
  }

  getUserInfo(): Observable<Person> {
    return this.http.get<Person>(API_URL.me);
  }

  forgotPassword(email: { email: string }): Observable<any> {
    return this.http.post(API_URL.reset_password, email);
  }

  validateResetPasswordToken(data: any) {
    return this.http.post(API_URL.reset_password_validate, data);
  }

  resetPassword(data: any) {
    return this.http.post(API_URL.reset_password_confirm, data);
  }

  setExpiredPassword(data: { password: string }) {
    return this.http.patch('/api/set-expired-password/', data);
  }

  getPasswordPolicy() {
    return this.http.get('/api/pw-policy/');
  }

  loadLarkSDK(): Promise<void> {
    return new Promise((resolve) => {
      // h5sdk is used in Lark App.
      // I found that window.h5sdk exists only if user open this website in Lark app only.
      //   I think somehow library can detect if user open website on Lark app or not.
      if (this.larkSDKLoaded) {
        resolve();
      }
      const myWindow: any = window;
      if (myWindow.h5sdk) {
        myWindow.h5sdk.ready(() => {
          this.larkSDKLoaded = true;
          this.zone.run(() => {
            resolve();
          });
        });
      } else {
        this.larkSDKLoaded = true;
        resolve();
      }
    });
  }
}
