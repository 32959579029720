<div class="dept-dd-container row">
    <label class="col-12">{{'LOA.CHOOSE-DEPATMENT' | translate}}</label>
    <div class="dept-dd-dropdown col-md-6"
        *ngFor="let dropdown of dropdownList; let i = index">
        <ng-select *ngIf="!refreshDropdown"
            class="w-100 m-t-sm-3"
            [items]="dropdown"
            bindValue="id"
            [ngClass]="i > 1 ? 'custom-mt': ''"
            [notFoundText]="'SELECT.No items found'|translate"
            bindLabel="{{translated === 'th' ? 'department_name' : 'department_name_en'}}"
            placeholder="{{'MEMOS.SELECT' | translate}}"
            [(ngModel)]="selectedDepartmentID[i] ? selectedDepartmentID[i]  : currentDepartmentId"
            (change)="selectedDepartment(i, $event)">
        </ng-select>
        <div *ngIf="i < dropdownList.length - 1"
            class="dept-dd-separator">/</div>
    </div>

    <div class="col-md-6 text-end-sm m-t-sm-3"
        [ngClass]="dropdownList.length > 1 ? 'mt-3':''">
        <!-- TODO: Need checking `dropdownList.l` -->
        <button class="btn btn-clear mr-2 p-btn"
            *ngIf="showNextLevelButton"
            (click)="showNextLevelButtonClicked()">{{$any(dropdownList).l}}
            {{'DEPARTMENT.SELECT-SUB-DEPARTMENT' | translate}}</button>
        <button class="btn btn-clear p-btn"
            (click)="clearData()">{{clearLabel | translate}}</button>
    </div>
</div>
