<div class="content d-flex align-items-center flex-column justify-content-center">
  <img class="mb-4 pb-2"
      alt=""
      [src]="(theme.logo_rec | secure | async)"
      [style.max-height.px]="35">
  <app-svg-page-placeholder
      class="pt-2"
      [style.width.px]="315"
      [height]="152"
      [width]="315"
      [standImage]="{src: 'assets/images/pictograms/folder-shield-lock.svg#folder-shield-lock', width: 142, height: 152, x: 103, y: 0}"
      [backgroundImage]="{fill: '#E8EEFB', width: 315, height: 104, y: 23}">
  </app-svg-page-placeholder>
  <label class="text-black-pantone fw-medium fs-u4 mb-u0">
    {{ "ACC-LOCK.Title" | translate }}
  </label>
  <div class="text-black-pantone fs-l2 mb-u0 text-center">
    <div>{{ "ACC-LOCK.SUB-1-1" | translate }}</div>
    <div>{{ "ACC-LOCK.SUB-1-2" | translate }}</div>
  </div>
  <label class="text-gray-neutrals fs-l2 mb-u0">
    {{ "ACC-LOCK.or" | translate }}
  </label>
  <label class="text-black-pantone fw-medium fs-u4 mb-u0">
    {{ "ACC-LOCK.UNLOCK-NOW" | translate }}
  </label>
  <div class="text-black-pantone fs-l2 mb-u0 text-center">
    <div>{{ "ACC-LOCK.SUB-2-1" | translate }}</div>
    <div>{{ "ACC-LOCK.SUB-2-2" | translate }}</div>
  </div>
  <button class="btn btn-std fs-base-l0 btn-std-prim mt-4 pt-2"
      (click)="goToLogin()"
      [style.width.px]="315"
      type="button">
    {{ "ACC-LOCK.LOGIN-WITH-OTHER-ACC" | translate }}
  </button>
  <button class="btn btn-std fs-base-l0 btn-std-2ry mt-4 pt-2"
      (click)="goToForgetPassword()"
      [style.width.px]="315"
      type="button">
    {{ 'PROFILE.Forget Password' | translate }}
  </button>
  <app-lang-selector class="lang"
      [style]="'dropdown'"
      [enableHover]="false"
  ></app-lang-selector>
</div>