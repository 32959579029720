import { GroundColor } from '@shared/models/common.model';
import { MemoStatus } from '@shared/models/memo.model';

export const DOCUMENT_PERMISSION_LIMIT = [
  {
    label:
      'People in LOA, CC, and department are allowed to view this document',
    label_th:
      'ให้ผู้ที่อยู่ในสายอนุมัติ สำเนาถึง และแผนกเข้าถึงเอกสารนี้ได้',
    value: false,
  },
  {
    label:
      'Only people in LOA and CC are allowed to view this document',
    label_th:
      'ให้ผู้ที่อยู่ในสายอนุมัติ และสำเนาถึง เข้าถึงเอกสารนี้ได้เท่านั้น',
    value: true,
  },
];

export const MEMO_NUMBER_LIST = [
  {
    name: 'Auto-Generated',
    id: false,
  },
  {
    name: 'Custom Memo Number',
    id: true,
  },
];

export const LOA_SIGN_USER_CHOICES = [
  { value: 'user', label_en: 'user', label: 'ผู้ที่อยู่ในระบบ' },
  {
    value: 'none user',
    label_en: 'non-user',
    label: 'ผู้ที่ไม่อยู่ในระบบ',
  },
];

export const LOA_SIGN_NONE_USER_PLACEHOLDER = {
  name: 'Contact Name',
  job_position: 'Job Position',
  email: 'Email Address',
  phone_number: 'Phone Number',
};

export const PRE_POSITIONS: Array<SignaturePosition | any> = [
  {
    sequence: 0,
    name: 'watermark',
    positions: null,
    type: 'watermark',
  },
  {
    sequence: 0,
    name: 'date',
    positions: null,
    type: 'date',
  },
  {
    sequence: 1,
    name: 'memo number',
    positions: null,
    type: 'memo_number',
  },
];
export const CHARACTER_LIMIT = {
  littleLimit: 20,
  shortLimit: 60,
  longLimit: 200,
  textLimit: 250,
  extraLimit: 500,
};

export const MemoStatusColors: {
  [k in MemoStatus]?: Partial<GroundColor>;
} = {
  approved: {
    background: '#ebf9ee',
    foreground: '#258d3f',
  },
  draft: {
    background: '#eaeaed',
    foreground: '#707485',
  },
  expired: {
    background: '#e8dccf',
    foreground: '#876b4b',
  },
  pending: {
    background: '#e8eefb',
    foreground: '#1750c0',
  },
  rejected: {
    background: '#fff4e6',
    foreground: '#e88800',
  },
  terminated: {
    background: '#feebeb',
    foreground: '#df3131',
  },
  trashed: {
    background: '#e8eefb',
    foreground: '#2d5b8f',
  },
} as const;

export const MemoStatusIconPaths: {
  [k in MemoStatus]: string;
} = {
  approved: 'assets/images/icons/marks/circle-check.svg',
  draft: 'assets/images/icons/files/doc-pen.svg',
  expired: 'assets/images/icons/time/stopwatch-slash.svg',
  pending: 'assets/images/icons/time/clock-three.svg',
  rejected: 'assets/images/icons/arrows/rotate-right.svg',
  terminated: 'assets/images/icons/marks/circle-xmark.svg',
  trashed: 'assets/images/icons/marks/circle-xmark.svg',
};

export const MemoStatuses = {
  Approved: 'approved',
  Draft: 'draft',
  Expired: 'expired',
  Pending: 'pending',
  Rejected: 'rejected',
  Terminated: 'terminated',
  Trashed: 'trashed',
} as const;

export const MemoStatusesTh = {
  approved: 'อนุมัติ',
  draft: 'ฉบับร่าง',
  expired: 'หมดอายุ',
  pending: 'รอดำเนินการ',
  rejected: 'รอการแก้ไข',
  terminated: 'ไม่อนุมัติ',
  trashed: 'รายการในถังขยะ',
} as const;

export const MemoApproverStatuses = {
  ...MemoStatuses,
  NotStarted: 'not_started',
  Canceled: 'canceled',
} as const;

export interface SignaturePosition {
  sequence: number;
  name: string;
  positions: Array<SingleSignaturePosition | any> | any;
  type?: string;
}

export interface SingleSignaturePosition {
  page: number;
  X: number;
  Y: number;
  id?: string;
  H?: number;
  W?: number;
  string?: string;
  fontSize?: number;
  fontStyle?: { name: string; value: string };
  initialPos?: { x: number; y: number };
  color?: string;
  colorForRender?: { r: number; g: number; b: number };
  dateFormat?: string;
  enableSetting: boolean;
  pageList: number[];
  pageIdList?: string[];
}

export const NO_PERMISSION_WORDING: {
  [key: string]: any;
} = {
  revising: {
    iconType: 'svg',
    iconPath: 'assets/images/pictograms/box.svg#box',
    title: 'PERMISSION.Memo was returned for revising',
    subtitle:
      'PERMISSION.You cannot access the document. It was returned to validate document again',
  },
  recalling: {
    iconType: 'svg',
    iconPath: 'assets/images/pictograms/box.svg#box',
    title: 'PERMISSION.Memo was recalled for revising',
    subtitle:
      'PERMISSION.You cannot access this document. The requester retrieve this memo to edit',
  },
  no_perm: {
    iconType: 'svg',
    iconPath: 'assets/images/pictograms/doc.svg#doc',
    title: 'PERMISSION.Sorry you dont have permission',
    subtitle: 'PERMISSION.This document is set to privacy settings',
  },
};
