export const FONT_LIST = [
  { name: 'THSarabunNew', value: 'THSarabunNew' },
  { name: 'THSarabunNewBold', value: 'THSarabunNewBold' },
  { name: 'THSarabunNewItalic', value: 'THSarabunNewItalic' },
  { name: 'THSarabunNewBoldItalic', value: 'THSarabunNewBoldItalic' },
];

export const DATE_TYPE_LIST = [
  {
    value: 'published_at',
    label: 'Publish Date',
    label_th: 'วันที่เผยแพร่',
  },
  {
    value: 'finished_at',
    label: 'Finished Date',
    label_th: 'วันที่เสร็จสิ้น',
  },
];

export const MARKER_IDENTITY_KEYS = [
  'positions',
  'approved_date_positions',
  'comment_positions',
] as const;

export const SIGNATURE_COLOR_LIST = [
  '#000000',
  '#4169e1',
  '#EB2B36',
  '#266D1F',
];
