<div class="signature-sign-container"
    [style.--bg]="'#658FE2'">
  <div class="d-flex position-absolute w-100 h-100 align-items-center justify-content-between">
    <div class="signature-sign-options position-relative"
        (clickOutside)="closeTool()">
      <ng-template #toolsSign
        let-iconPath="iconPath">
        <svg-icon [src]="iconPath"
            [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'stroke': '#1958D3' }"
            class="pointer">
        </svg-icon>
      </ng-template>
      <div class="position-relative">
        <button class="tools-btn"
            (click)="onEditSizeLine()">
          <ng-container *ngTemplateOutlet="toolsSign; context:
                    { iconPath: 'assets/images/icons/editing/pen.svg' } "></ng-container>
        </button>
        <ng-container *ngTemplateOutlet="ThicknessSetting"></ng-container>
      </div>
      <div class="position-relative">
        <button class="tools-btn"
            (click)="onClickColorPicker()">
          <ng-container *ngTemplateOutlet="toolsSign; context:
                    { iconPath: 'assets/images/icons/color-palette.svg' } "></ng-container>
        </button>
        <ng-container *ngTemplateOutlet="ColorPicker"></ng-container>
      </div>
      <button class="tools-btn"
          (click)="onUndoCanvas()">
        <ng-container *ngTemplateOutlet="toolsSign; context:
                    { iconPath: 'assets/images/icons/reset.svg' } "></ng-container>
      </button>
      <button class="tools-btn"
          (click)="onRedoCanvas()">
        <ng-container *ngTemplateOutlet="toolsSign; context:
                    { iconPath: 'assets/images/icons/redo.svg' } "></ng-container>
      </button>
      <button class="tools-btn"
          (click)="onClearCanvas()">
        <ng-container *ngTemplateOutlet="toolsSign; context:
                    { iconPath: 'assets/images/icons/editing/trash.svg' } "></ng-container>
      </button>
    </div>
  </div>
  <div class="col-12">
    <div #canvasContainer
        class="signature-sign-wrap"
        style="height: 300px;">
      <canvas-whiteboard #canvasWhiteboard
          [clearButtonEnabled]="false"
          [drawButtonEnabled]="false"
          [drawingEnabled]="true"
          [undoButtonEnabled]="false"
          [redoButtonEnabled]="false"
          [colorPickerEnabled]="false"
          [saveDataButtonEnabled]="false"
          [shapeSelectorEnabled]="false"
          [lineWidth]="getSignatureThickness(canvasContainer.offsetWidth)"
          [startingColor]="'transparent'"
          [shouldDownloadDrawing]="false"
          [strokeColor]="selectedColor"
          (onSave)="onSave($event)"
          (mousedown)="closeTool()">
      </canvas-whiteboard>
    </div>
  </div>
</div>

<div *ngIf="showBtn"
    class="d-flex justify-content-end mt-4"
    [ngClass]="{'pt-2 mb-btn-bottom': !isModal}">
  <button type="button"
      class="btn btn-std btn-std-2ry fs-base-l0 fw-semibold"
      [ngClass]="isModal ? 'btn-h-sm-res fs-base-l4 mx-1 py-base-l8 px-base-l4 btn-width': 'me-l5'"
      (click)="back.emit()">
    {{ cancelText | translate }}
  </button>
  <button type="button"
      class="btn btn-std btn-std-prim fs-base-l0 fw-semibold"
      [ngClass]="{'btn-h-sm-res fs-base-l4 mx-1 py-base-l8 px-base-l4 btn-width': isModal}"
      [ladda]="isLoading"
      (click)="save()">
    {{ "MEMOS.NEXT" | translate }}
  </button>
</div>

<ng-template #ThicknessSetting>
  <div *ngIf="showPopupLine"
      class="popup-window resize-popup-window">
    <div class="d-flex">
      <input type="range"
          min="1"
          max="30"
          [(ngModel)]="signatureThickness"
          (change)="resizeLine()"
          class="slider">
      <span class="ms-2">{{ signatureThickness }}</span>
    </div>
    <div class="text-end">
      <span class="text-asterisk pointer"
          (click)="resetSizeLine()">reset</span>
    </div>
  </div>
</ng-template>

<ng-template #ColorPicker>
  <div *ngIf="showColorPicker"
      class="popup-window color-popup-window">
    <!-- default color list-->
    <div class="color-list">
      <span class="color-item-border"
          *ngFor="let color of signatureColorList"
          [style.border-color]="color === selectedColor ? color : 'white'"
          (click)="onSelectedColor(color)">
        <span class="color-item"
            [style.background]="color"></span>
      </span>
    </div>
    <color-chrome [disableAlpha]="true"
        (onChange)="changeComplete($event)">
    </color-chrome>
  </div>
</ng-template>