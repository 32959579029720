<label class="fw-medium fs-base-l0 text-black-neutrals">
  {{"COMMON.Upload"|translate}}
</label>
<input type="file"
    hidden
    accept=".xlsx, .xls, .csv"
    #multipleFileUpload
    (change)="selectFile($event)"
    [disabled]="disableUpload"
    #fileSignature>
<div *ngIf="fileInput"
    class="mt-2">
  <strong class="pointer text-blue"
      (click)="downloadFile(fileInput)">
    {{fileInput.name}}
    <a class="text-asterisk"
        (click)="deleteFile(fileSignature)">
      <i class="fas fa-times"></i>
    </a>
  </strong>
</div>
<div>
  <ngx-file-drop class="file-drop pointer"
      dropZoneLabel="Browse or Drop files here"
      (click)="multipleFileUpload.click()">
    <ng-template ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector">
      <div class="d-flex flex-column align-items-center gap-1">
        <svg class="pointer icon-btn-svg ">
          <use href="assets/images/icons/apps/window-arrow-up.svg#window-arrow-up"
              stroke="#707485"
              height="20"
              width="20">
          </use>
        </svg>
        <div>
          <span class="fw-bold fs-base-l2 text-blue-tang ps-2 my-1">
            {{ 'MEMOS.Choose a file or drag & drop it here' | translate}}
          </span>
        </div>
        <div class="fs-base-l4 text-red-scarlet px-3 text-center">
          {{'MEMOS.The maximum size limit per file is 25 MB and the total files size limit is 45 MB' |translate }}
        </div>
        <div class="my-1">
          <button class="btn btn-std btn-h btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
              type="button">
            {{'MEMOS.Browse-File' |translate}}
          </button>
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>
</div>