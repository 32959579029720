import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LOOP_COLOR_LIST } from '@shared/constants/theme.constant';
import { SecurePipe } from '@shared/pipes/secure.pipe';
import * as _ from 'lodash';
import { ProfileService } from 'src/app/modules/profile/shared/profile.service';

@Component({
  selector: 'app-profile-display',
  standalone: true,
  imports: [CommonModule, SecurePipe],
  templateUrl: './profile-display.component.html',
  styleUrls: ['./profile-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDisplayComponent implements OnChanges {
  @Input() autoInitName = false;
  @Input() bindInitName = 'initial_name';
  @Input() bindName = 'full_name';
  @Input() bindPhoto = 'photo';
  @Input() defaultPhoto?: string | null;
  @Input() person: { [k: string]: any } = {};
  @Input() size = 24;
  @Input() pointer = false;
  @Input() isInvalid = false;
  @Input() borderColor: string | undefined;

  @Output() clickProfile = new EventEmitter<void>();

  colorProfile: string;

  private profile = inject(ProfileService);

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['person'] &&
      this.person &&
      this.person[this.bindName]
    ) {
      this.colorProfile = this.combineBgProfile(
        this.person[this.bindName],
      );
    }
  }

  onClick() {
    this.clickProfile.emit();
  }

  combineBgProfile(fullName?: string): string {
    const countText = fullName?.length || 0;
    const randomIndex = countText % LOOP_COLOR_LIST.length;
    return LOOP_COLOR_LIST[randomIndex];
  }

  get initialName(): string {
    return this.autoInitName
      ? this.profile
          .abbreviateFullName(this.name)
          .replace(/\./g, '')
          .toUpperCase()
      : this.person[this.bindInitName] || '';
  }

  get name(): string {
    return _.get(this.person, this.bindName) || '';
  }

  get photo(): string {
    return _.get(this.person, this.bindPhoto) || '';
  }
}
