import { Component, inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ThemeList,
  ThemeService,
} from '@shared/service/theme.service';
import { ActivatedRoute } from '@angular/router';
import { versionInfo } from '../../../../version-info';
import { DeviceType } from '@shared/models/common.model';
import { DisplayService } from '@core/services/display.service';

@Component({
  selector: 'app-dashboard-container',
  templateUrl: './dashboard-container.component.html',
  styleUrls: ['./dashboard-container.component.scss'],
})
export class DashboardContainerComponent implements OnDestroy {
  theme: ThemeList;
  content: string;

  version: any = versionInfo.hash;
  deviceType: DeviceType = 'desktop';

  private displayService = inject(DisplayService);
  private subscription = new Subscription();

  constructor(
    private themeService: ThemeService,
    private activatedRoute: ActivatedRoute,
  ) {
    const sub = this.themeService.data.subscribe((theme) => {
      this.theme = theme;
    });
    this.subscription.add(sub);
    this.observeDisplay();
    this.initializeRoute();
  }

  observeDisplay(): void {
    const sub = this.displayService
      .getBreakpointDeviceObserver()
      .subscribe({
        next: (res) => {
          this.deviceType = res;
        },
      });
    this.subscription.add(sub);
  }

  initializeRoute(): void {
    const rootToParentRoute: string[] = [
      ...this.activatedRoute.snapshot.pathFromRoot,
    ]
      .map((route) => route.url.join('/'))
      .filter((url) => !!url);
    if (rootToParentRoute) {
      this.content = rootToParentRoute.pop() as string;
    }
  }

  get showDashboard() {
    return this.deviceType === 'desktop' || this.content === 'login';
  }

  changeContent(ct: string) {
    this.content = ct;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
