<div class="content">
  <div class="box row flex-column-reverse mx-0 flex-lg-row">
    <app-saved-dashboard *ngIf="showDashboard"
        class="p-0 flex-grow-1 d-md-flex col-lg-8 d-lg-block">
    </app-saved-dashboard>
    <div class="content-box col-lg-4"
        [ngClass]="{'no-dashboard': !showDashboard}">
      <div class="context d-flex flex-column">
        <app-lang-selector *ngIf="content !== 'forget-password'"
            class="align-self-end mb-base-u12"
            [ngClass]="{'d-md-none': content === 'login', 'lang-top': content !== 'login'}"
            [style]="'dropdown'"
            [enableHover]="false"
        ></app-lang-selector>

        <img class="mb-base-u8 align-self-center"
            alt=""
            [src]="(theme.logo_rec | secure | async)"
            [style.max-height.px]="deviceType === 'desktop' ? 35: 25">

        <ng-container [ngSwitch]="content">
          <app-login *ngSwitchCase="'login'"
              (forgetPassword)="changeContent('forget-password')">
          </app-login>

          <app-forget-password *ngSwitchCase="'forget-password'"
              (login)="changeContent('login')">
          </app-forget-password>

          <app-set-password *ngSwitchCase="'reset-password'"
              class="content-overflow">
          </app-set-password>

          <app-reset-password *ngSwitchDefault
              class="content-overflow"
          ></app-reset-password>
        </ng-container>
      </div>

      <ng-container *ngIf="['login', 'forget-password'].includes(content)">
        <app-lang-selector class="d-md-block lang"
            [ngClass]="{'d-none': content === 'login'}"
        ></app-lang-selector>
        <div class="d-none d-md-block version fs-l8 text-gray-roman-silver">v.{{ version || '0.0.0' }}</div>
      </ng-container>

    </div>
  </div>
</div>