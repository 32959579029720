import { BreakpointObserver } from '@angular/cdk/layout';
import {
  inject,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DeviceType } from '@shared/models/common.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from './api.constant';
import * as d3 from 'd3-color';
import { HSLColor } from 'd3-color';

export interface ThemeList {
  id: number;
  active: boolean;
  // New theme color schema
  logo_round: string;
  logo_rec: string;
  primary_color: string;
  secondary_color: string;
  default: number;
  // TODO: Mock data to be delete
  name: string;
  // Logo
  display_email: string;
  display_pdf: string;
  display_login: string;
  display_sidebar: string;
  // Login
  bg_login_color: string;
  bg_login_second_color: string;
  box_login_color: string;
  text_login_color: string;
  text_box_login_color: string;
  button_login_color: string;
  text_button_login_color: string;
  // Theme
  bg_color_primary: string;
  bg_color_secondary: string;
  text_color: string;
  hovered_text_color: string;
  header_table_color: string;
  text_header_table_color: string;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public stdHueColors: { [k: string]: number };
  public hslStdColorSets: HslColorSet[] = [
    {
      name: 'std-color-1',
      hueName: 'primary',
      saturationDiff: -51, // 49%
      lightnessDiff: 29, // 79%
    },
    {
      name: 'std-color-2',
      hueName: 'primary',
      saturationDiff: -53, // 47%
      lightnessDiff: 43, // 93%
    },
    {
      name: 'std-color-3', //primary default
      hueName: 'primary',
      saturationDiff: 0, // 100%
      lightnessDiff: -17, // 33%
    },
    {
      name: 'std-color-4',
      hueName: 'secondary',
      saturationDiff: 0, // 100%
      lightnessDiff: 47.06, // 52.94%
    },
    {
      name: 'std-color-5', //text
      hueName: 'primary',
      saturationDiff: -100, // 0%
      lightnessDiff: 50, // 100%
    },
    {
      name: 'std-color-6', //primary hover
      hueName: 'primary',
      saturationDiff: -43, // 57%
      lightnessDiff: -4, // 46%
    },
    {
      name: 'std-color-7', //primary pressed
      hueName: 'primary',
      saturationDiff: 0, // 100%
      lightnessDiff: -32, // 18%
    },
    {
      name: 'std-color-8', //secondary default
      hueName: 'secondary',
      saturationDiff: 0, // 100%
      lightnessDiff: 3, // 53%
    },
    {
      name: 'std-color-9', //secondary hover
      hueName: 'secondary',
      saturationDiff: 0, // 100%
      lightnessDiff: 10, // 60%
    },
    {
      name: 'std-color-10', //secondary pressed
      hueName: 'secondary',
      saturationDiff: 0, // 100%
      lightnessDiff: -15, // 35%
    },
    {
      name: 'std-color-11', // base background
      hueName: 'primary',
      saturationDiff: -60, // 40%
      lightnessDiff: 48, // 98%
    },
    {
      name: 'std-color-12', // highlight background
      hueName: 'primary',
      saturationDiff: -50, // 50%
      lightnessDiff: -48, // 98%
    },
  ];
  themeList: ThemeList | any;
  private setTheme = new BehaviorSubject<ThemeList | any>(null);
  data = this.setTheme.asObservable();

  private renderer: Renderer2;
  private rendererFactory = inject(RendererFactory2);

  constructor(
    private http: ApiService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  applyColorsToCssVar(): void {
    let cssText = '';
    Object.keys(this.stdHueColors).forEach((hueKey) => {
      cssText +=
        '--hue-' + hueKey + ': ' + this.stdHueColors[hueKey] + '; ';
    });
    this.hslStdColorSets.forEach((color) => {
      cssText +=
        '--' + color.name + ':' + this.getHslColorValue(color) + ';';
      const hsl = this.getAbsoluteHsl(color);
      const lightness = +(hsl.l * 100 + color.lightnessDiff).toFixed(
        2,
      );
      // Create text color for contrast to background
      //* In future we can add case for lightness >= 50
      if (lightness < 50) {
        cssText += '--text-bg-' + color.name + ':' + '#fff' + ';';
      }
    });
    const styleEl = this.renderer.createElement('style');
    styleEl.innerHTML = `:root{${cssText}}`;
    const headEl = document.getElementsByTagName('head');
    this.renderer.appendChild(headEl.item(0), styleEl);
  }

  public fetchData(): Observable<ThemeList[]> {
    return this.http.get(API_URL.themes);
  }

  public loadTheme(id: number): Observable<ThemeList> {
    return this.http.get(API_URL.themes + id + '/');
  }

  public updateTheme(theme: any, id: number) {
    return this.http.patch(API_URL.themes + id + '/', theme);
  }

  public createTheme(theme: any) {
    return this.http.post(API_URL.themes, theme);
  }

  public deleteTheme(id: any) {
    return this.http.delete(API_URL.themes + id + '/');
  }

  public removeEmailLogo(id: any) {
    return this.http.delete(API_URL.themes + id + '/logo-email/');
  }

  /**
   * @param name A color name Ex. `std-color-1`
   */
  getHslColorSetByName(name: string): HslColorSet | undefined {
    return this.hslStdColorSets.find((color) => color.name === name);
  }

  /**
   * Example:
   * ```typescript
   * const hslColorSet: HslColorSet = {
   *   name: 'std-color-1',
   *   hueName: 'primary',
   *   saturation: '49%',
   *   lightness: '79%',
   * };
   * getHslColorValue(hslColorSet); // 173, 49%, 79%
   * ```

   * @param hslColorSet
   * @returns the HSL color value example: 173, 49%, 79%
   */
  getHslColorValue(hslColorSet: HslColorSet): string {
    const hsl = this.getAbsoluteHsl(hslColorSet);
    let saturation = Math.round(
      hslColorSet.saturationDiff + hsl.s * 100,
    );
    saturation < 0 ? (saturation = 0) : saturation;

    let lightness = Math.round(
      hslColorSet.lightnessDiff + hsl.l * 100,
    );
    lightness < 0 ? (lightness = 0) : lightness;

    const hslColor = `${hsl.h},` + ` ${saturation}%, ${lightness}%`;
    return hslColor;
  }

  /**
   * Get HSL color value by name.

   * Example:
   * ```typescript
   * getHslColorValueByName('std-color-1'); // 173, 49%, 79%
   * ```
   */
  getHslColorValueByName(name: string): string | undefined {
    const color = this.getHslColorSetByName(name);
    if (!color) {
      return;
    }
    return this.getHslColorValue(color);
  }

  getAbsoluteHsl(hslColorSet: HslColorSet): HSLColor {
    const colorString =
      this.stdHueColors[hslColorSet.hueName].toString();
    return d3.hsl(colorString);
  }

  // TODO: move to new service override BreakpointObserver class
  getDeviceTypeByBreakpoint(): DeviceType {
    if (this.isMobileSmallScreen()) {
      return 'mobile';
    } else if (this.isTablet()) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  public getActiveTheme(): Observable<ThemeList> {
    return this.http.get(API_URL.theme_active);
  }

  isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 768px)');
  }

  isMobileSmallScreen() {
    return this.breakpointObserver.isMatched('(max-width: 576px)');
  }

  isTabletOrMobile() {
    return this.breakpointObserver.isMatched(['(max-width: 1024px)']);
  }

  isTablet() {
    return this.breakpointObserver.isMatched('(max-width: 1200px)');
  }

  setThemeList(theme: ThemeList) {
    this.themeList = theme;
    this.stdHueColors = {
      primary: this.themeList.primary_color,
      secondary: this.themeList.secondary_color,
    };
    this.setTheme.next(this.themeList);
    this.applyColorsToCssVar();
  }

  themeSetting() {
    this.setThemeList(this.themeList);
  }
}

export interface HslColorSet {
  name: string;
  hueName: string;
  saturationDiff: number;
  lightnessDiff: number;
}
