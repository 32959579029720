<ng-template #selectSignMethodModal
    class="modal-dialog">
  <div class="modal-header">
    <h4 class="flex-grow-1 fs-base-u5 fw-bold mb-0">
      {{ title | translate }}
    </h4>
    <div class="col-auto p-0">
      <svg-icon src="assets/images/icons/closeX.svg"
          class="pointer"
          [svgStyle]="{ 'width.px': 22, 'height.px': 22 }"
          (click)="close()"></svg-icon>
    </div>
  </div>
  <div class="modal-body py-u8 px-u12">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="'select'"
          [ngTemplateOutlet]="select"></ng-container>

      <ng-container *ngSwitchCase="'text'"
          [ngTemplateOutlet]="textSig"></ng-container>

      <ng-container *ngSwitchCase="'upload'"
          [ngTemplateOutlet]="uploadSig"></ng-container>

      <ng-container *ngSwitchCase="'sign'"
          [ngTemplateOutlet]="signSig"></ng-container>

      <ng-container *ngSwitchCase="'crop'"
          [ngTemplateOutlet]="cropSig"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #select>
  <ng-template #svgIcon
    let-iconPath="iconPath">
    <div class="icon-border">
      <svg stroke="currentColor"
          height="24"
          width="24">
        <use height="24"
            width="24"
            [attr.href]="iconPath">
        </use>
      </svg>
    </div>
  </ng-template>
  <p *ngIf="numberOfSignature"
      class="text-center numbers-of-signature">
    {{
      ('SELECT-SIGN-METHOD.You are about to sign $numberOfSignature of $countPage pages.'|translate)
        .replaceAll('$numberOfSignature', numberOfSignature)
        .replaceAll('$countPage', countPage)
    }}
  </p>
  <div class="row btn-container"
      [style.--bg]="'#fff'">
    <div *ngIf="enableTextSignature"
        class="col-12 my-2"
        [ngClass]="col">
      <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
          type="button"
          (click)="textClick()">
        <ng-container *ngTemplateOutlet="svgIcon;
            context: { iconPath: 'assets/images/icons/documents/text.svg#text' }"></ng-container>
        <span class="d-inline-block mt-3 fw-medium">
            {{ "SELECT-SIGN-METHOD.SIGN-BY-TYPING" | translate }}
          </span>
      </button>
    </div>
    <div *ngIf="enableUsingStoredSignature"
        class="col-12 my-2"
        [ngClass]="col">
      <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
          type="button"
          (click)="otpEnable ? openOtpModal() : submit(true)">
        <ng-container *ngTemplateOutlet="svgIcon;
            context: { iconPath: 'assets/images/icons/sign-saved.svg#sign-saved' }"></ng-container>
        <span class="d-inline-block mt-3 fw-medium">{{ "SELECT-SIGN-METHOD.STORED" | translate }}</span>
      </button>
    </div>
    <div class="col-12 my-2"
        [ngClass]="col">
      <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
          type="button"
          [disabled]="!enableUploadSignature"
          (click)="uploadClick()">
        <ng-container *ngTemplateOutlet="svgIcon;
            context: { iconPath: 'assets/images/icons/documents/upload.svg#upload' }"></ng-container>
        <span class="d-inline-block mt-3 fw-medium">{{ "SELECT-SIGN-METHOD.UPLOAD" | translate }}</span>
      </button>
    </div>

    <div class="col-12 my-2"
        [ngClass]="col">
      <button class="btn btn-outline shadow-none d-flex align-items-center justify-content-center mb-3 flex-column"
          type="button"
          (click)="signClick()">
        <ng-container *ngTemplateOutlet="svgIcon;
            context: { iconPath: 'assets/images/icons/editing/pen.svg#pen' }"></ng-container>
        <span class="d-inline-block mt-3 fw-medium">{{ "SELECT-SIGN-METHOD.SIGN-NOW" | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #textSig>
  <app-text-signature
      [selectOnly]="!customTextSignature"
      [cancelText]="'APPROVAL.BACK'"
      [confirmText]="'MEMOS.NEXT'"
      [fullName]="profile.full_name"
      [isModal]="true"
      (signatureSaveClick)="save($event)"
      (cancelClick)="_back()">
    <ng-template appHeaderDetail></ng-template>
  </app-text-signature>
</ng-template>

<ng-template #uploadSig>
  <app-signature-upload style="height: 300px"
      [isModal]="true"
      (back)="_back()"
      (next)="crop($event)"
  ></app-signature-upload>
</ng-template>

<ng-template #signSig>
  <div class="text-black-neutrals fw-medium mb-l8">
    {{ 'MEMOS.PLEASE-SIGN-SIGNATURE' | translate }}
  </div>
  <app-signature-sign-now
      [isModal]="true"
      (back)="_back()"
      (next)="crop($event)"
  ></app-signature-sign-now>
</ng-template>

<ng-template #cropSig>
  <h6 class="fs-base-l0 fw-medium text-black-neutrals d-inline-block">
    {{ 'SELECT-SIGN-METHOD.Select signature scope your signature' | translate }}
  </h6>
  <app-signature-cropper *ngIf="signImage"
      [isModal]="true"
      [outputFormat]="'File'"
      [signImageBlob]="signImage"
      [autoCrop]="mode === 'sign'"
      [undoStackLength]="mode === 'sign' ? 2: 1"
      [againText]="mode === 'sign' ? 'SELECT-SIGN-METHOD.Sign Again': 'COMMON.Upload Again'"
      (next)="save($any($event))"
      (back)="_back()"
  ></app-signature-cropper>
</ng-template>

<app-otp-modal [header]="header"
    [(otp)]="otp"
    [isSaved]="isSaved"
    (otpChange)="verifyOTP()"
    (closeModal)="close()"></app-otp-modal>
